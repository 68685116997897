import React, { useEffect, useRef } from "react";
import PSPDFKitWeb from "pspdfkit";

import { Column } from "./box";

const BaseURL = `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`;

const toolbarItems = [
  { type: "sidebar-thumbnails" },
  { type: "pager" },
  { type: "pan" },
  { type: "zoom-out" },
  { type: "zoom-in" },
  { type: "zoom-mode" },
  { type: "spacer" },
  { type: "layout-config" },
];

const PDF = ({ url, onError }) => {
  const containerRef = useRef();

  let instance = null;

  const load = async () => {
    if (url) {
      try {
        instance = await PSPDFKitWeb.load({
          pdf: url,
          container: containerRef.current,
          licenseKey: process.env.REACT_APP_PSPDF_LICENSE,
          baseUrl: BaseURL,
          toolbarItems,
        });
      } catch (e) {
        onError(e);
      }
    }
  };

  const unload = () => {
    if (instance || containerRef.current) {
      PSPDFKitWeb.unload(instance || containerRef.current);
    }
  };

  useEffect(() => {
    unload();
    load();

    return unload;
  }, [url]);

  return <Column ref={containerRef} width="100%" height="100%" />;
};

export default PDF;
