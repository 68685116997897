import React, { createContext, useState } from 'react';

const store = createContext({});
const { Provider } = store;

const StoreProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  return <Provider value={{ user, setUser }}>{children}</Provider>;
};

export { store, StoreProvider };
