import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import fetch from 'unfetch';
import styled from 'styled-components';
import moment from 'moment';

import config from '../config';
import { Row, Column } from '../components/box';
import PDF from '../components/pdf';
import Text from '../components/text';
import Button from '../components/button';
import Input from '../components/input';
import Checkbox from '../components/checkbox';
import Label from '../components/label';
import Layout from '../components/layout';
import Spinner from '../components/spinner';
import Form from '../components/form';
import Icon from '../components/icon';
import { ConfirmPopup, ErrorPopup, SuccessPopup } from '../components/popup';

const KEEP_ACTIVE_INTERVAL = 3000;

const parseDate = (dateString) => {
  const date = dateString.substring(
    dateString.indexOf('(') + 1,
    dateString.indexOf(')')
  );

  return moment(date, 'xZ').format('MM/DD/YYYY');
};

const DocSectionLabel = styled(Text)`
  &:not(:first-child) {
    margin-top: 8px;
  }
`;
DocSectionLabel.defaultProps = {
  fontWeight: 3,
  fontSize: 1,
  padding: 1,
  bg: 'black',
  color: 'white',
};
const DocLabel = styled(Text)``;
DocLabel.defaultProps = {
  fontWeight: 3,
  fontSize: 0,
  marginY: 1,
  color: 'grayDarker',
};

const DocPerson = ({ Primary, Secondary, Address }) => (
  <>
    <DocLabel>Primary</DocLabel>
    <Text>
      {Primary.First} {Primary.Last}
    </Text>
    {Secondary && (
      <>
        <DocLabel>Secondary</DocLabel>
        <Text>
          {Secondary.First} {Secondary.Last}
        </Text>
      </>
    )}
    <DocLabel>Address</DocLabel>
    <Text>{Address.Address1}</Text>
    {Address.Address2 && <Text>{Address.Address2}</Text>}
    <Text>
      {Address.City}, {Address.State} {Address.Zip}
    </Text>
  </>
);

const Divider = styled(Row)`
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.colors.black};
  margin: 8px 0 4px 0;
`;

const Document = ({
  document: { FileNumber, Sellers, Buyers, Partners, Properties, Dates },
}) => {
  return (
    <Column overflow="auto">
      {FileNumber && (
        <>
          <DocSectionLabel>File Number</DocSectionLabel>
          <Text marginTop={1}>{FileNumber}</Text>
        </>
      )}

      {Sellers && Sellers.length && (
        <>
          <DocSectionLabel>Sellers</DocSectionLabel>
          {Sellers.map((data, i) => (
            <>
              {i > 0 && <Divider />}
              <DocPerson {...data} />
            </>
          ))}
        </>
      )}

      {Buyers && Buyers.length && (
        <>
          <DocSectionLabel>Buyers</DocSectionLabel>
          {Buyers.map((data, i) => (
            <>
              {i > 0 && <Divider />}
              <DocPerson {...data} />
            </>
          ))}
        </>
      )}

      {Properties && Properties.length && (
        <>
          <DocSectionLabel>Properties</DocSectionLabel>
          {Properties.map(
            (
              {
                StreetNumber,
                StreetName,
                StreetSuffix,
                City,
                State,
                Zip,
                ParcelID,
              },
              i
            ) => (
              <>
                {i > 0 && <Divider />}
                <DocLabel>Address</DocLabel>
                <Text>
                  {StreetNumber} {StreetName} {StreetSuffix}
                </Text>
                <Text>
                  {City}, {State} {Zip}
                </Text>
                {ParcelID && (
                  <>
                    <DocLabel>Parcel ID</DocLabel>
                    <Text>{ParcelID}</Text>
                  </>
                )}
              </>
            )
          )}
        </>
      )}

      {Partners && Partners.length && (
        <>
          <DocSectionLabel>Partners</DocSectionLabel>
          {Partners.map(({ PartnerName, PrimaryEmployee }, i) => (
            <>
              {i > 0 && <Divider />}
              <DocLabel>Name</DocLabel>
              <Text>{PartnerName}</Text>
              <DocLabel>Primary Employee</DocLabel>
              <Text>
                {PrimaryEmployee.FirstName} {PrimaryEmployee.LastName}
              </Text>
            </>
          ))}
        </>
      )}

      {Dates && Dates.OpenedDate && (
        <>
          <DocSectionLabel>Opened Date</DocSectionLabel>
          <Text marginTop={1}>{parseDate(Dates.OpenedDate)}</Text>
        </>
      )}
    </Column>
  );
};

const FileForm = ({ file }) => {
  const [success, setSuccess] = useState();
  const [searchError, setSearchError] = useState();
  const [error, setError] = useState();
  const [nonRouteLoading, setNonRouteLoading] = useState();
  const [routeLoading, setRouteLoading] = useState();
  const [searchLoading, setSearchLoading] = useState();
  const [hasAddress, setHasAddress] = useState();
  const [isPayoff, setIsPayoff] = useState();
  const [document, setDocument] = useState();
  const [deleting, setDeleting] = useState(false);
  const [hasAddressError, setHasAddressError] = useState(false);
  const [payoffError, setPayoffError] = useState(false);

  const history = useHistory();

  const validateAddress = () => {
    let valid = true;
    if (hasAddress === undefined) {
      setHasAddressError(true);
      valid = false;
    }
    if (isPayoff === undefined) {
      setPayoffError(true);
      valid = false;
    }
    return valid;
  };

  const submitSearch = async (event) => {
    event.preventDefault();

    setSearchLoading(true);
    setSearchError(null);
    setDocument(null);
    const data = new FormData(event.target);
    const fileNumber = data.get('fileNumber');
    try {
      const response = await fetch(`${config.apiUrl}/resware/search`, {
        method: 'POST',
        body: JSON.stringify({
          fileNumber,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const json = await response.json();

      if (response.status === 200) {
        setDocument(json);
      } else if (response.status === 404 || response.status === 400) {
        setSearchError(json.error);
      } else {
        setError((json && json.error) || 'Internal Server Error');
      }
    } catch (e) {
      console.error(e);
      setError('Internal Server Error');
    }
    setSearchLoading(false);
  };

  const submitRoute = async () => {
    if (!validateAddress()) {
      return;
    }

    setRouteLoading(true);

    try {
      const response = await fetch(`${config.apiUrl}/resware/route`, {
        method: 'POST',
        body: JSON.stringify({
          destination: 'routed',
          filename: file.filename,
          fileID: document.FileID,
          fileNumber: document.FileNumber,
          isPayoff,
          hasAddress,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.status === 204) {
        setSuccess(true);
        setTimeout(() => {
          history.push('/');
        }, 1000);
      } else {
        setError(
          `The document you're attempting to route cannot be found in the queue. It's likely another user has processed the document.`
        );
      }
    } catch (e) {
      setError(e);
      console.error(e);
    }
    setRouteLoading(false);
  };

  const submitNonRoute = async () => {
    if (!validateAddress()) {
      return;
    }

    setNonRouteLoading(true);

    try {
      const response = await fetch(`${config.apiUrl}/resware/route`, {
        method: 'POST',
        body: JSON.stringify({
          filename: file.filename,
          destination: 'nonRouted',
          hasAddress,
          isPayoff,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.status === 204) {
        setSuccess(true);
        setTimeout(() => {
          history.push('/');
        }, 1000);
      } else {
        const json = await response.json();
        setError(
          `The document you're attempting to route cannot be found in the queue. It's likely another user has processed the document.`
        );
      }
    } catch (e) {
      console.error(e);
      setError(e);
    }
    setNonRouteLoading(false);
  };

  const handleDelete = () => {
    if (validateAddress()) {
      setDeleting(true);
    }
  };

  const submitDelete = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/resware/route`, {
        method: 'POST',
        body: JSON.stringify({
          destination: 'deleted',
          filename: file.filename,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.status === 204) {
        setSuccess(true);
        setTimeout(() => {
          history.push('/');
        }, 1000);
      } else {
        const json = await response.json();
        setError(
          `The document you're attempting to route cannot be found in the queue. It's likely another user has processed the document.`
        );
      }
    } catch (e) {
      setError(e);
      console.error(e);
    }
    setDeleting(false);
    history.push('/');
  };

  return (
    <>
      <Column
        justifyContent="space-between"
        flex={1}
        overflow="hidden"
        height="100%"
      >
        <Form onSubmit={submitSearch}>
          <Label htmlFor="fileNumber">File Number</Label>
          <Row alignItems="center">
            <Input required name="fileNumber" id="fileNumber" />
            <Button
              type="submit"
              loading={searchLoading}
              marginLeft={4}
              width="75px"
            >
              Search
            </Button>
          </Row>
        </Form>

        <Column flex={1} marginTop={3} overflow="hidden" height="100%">
          <Label>File Info</Label>
          <Column
            bg="grayLighter"
            borderRadius={1}
            flex={1}
            padding={2}
            overflow="auto"
          >
            {document ? (
              <Document document={document} />
            ) : (
              <Column flex={1} alignItems="center" justifyContent="center">
                {searchLoading ? (
                  <Spinner size={5} />
                ) : (
                  <Column padding={6} alignItems="center">
                    <Icon
                      icon={searchError ? 'delete' : 'document'}
                      size={32}
                      color="black"
                      marginBottom={5}
                    />
                    <Text textAlign="center" fontWeight={3}>
                      {searchError || 'Search for a File Number'}
                    </Text>
                  </Column>
                )}
              </Column>
            )}
          </Column>
        </Column>

        <Column marginTop={3} position="relative">
          <Column>
            <Label>Routable Property Address?</Label>
            {hasAddressError && (
              <Text marginBottom={2} color="red">
                Please select Yes or No before submitting
              </Text>
            )}
            <Checkbox
              label="Yes"
              checked={hasAddress}
              onChange={setHasAddress}
            />
            <Checkbox
              label="No"
              marginTop={2}
              checked={hasAddress === false}
              onChange={(value) => setHasAddress(!value)}
            />
          </Column>

          <Column marginTop={3}>
            <Label>Payoff?</Label>
            {payoffError && (
              <Text marginBottom={2} color="red">
                Please select Yes or No before submitting
              </Text>
            )}
            <Checkbox label="Yes" checked={isPayoff} onChange={setIsPayoff} />
            <Checkbox
              marginTop={2}
              label="No"
              checked={isPayoff === false}
              onChange={(value) => setIsPayoff(!value)}
            />
          </Column>

          <Row alignItems="center" marginTop={3}>
            <Button
              flex={1}
              disabled={!document || !document.FileID}
              loading={routeLoading}
              onClick={submitRoute}
            >
              Route
            </Button>
            <Button
              flex={1}
              marginX={4}
              variant="secondary"
              loading={nonRouteLoading}
              onClick={submitNonRoute}
            >
              No Route
            </Button>
            <Button flex={1} variant="gray" onClick={handleDelete}>
              Delete
            </Button>
          </Row>

          {!file.available && (
            <Column
              borderRadius={1}
              width="100%"
              height="100%"
              position="absolute"
              bg="rgba(0,0,0,.8)"
              alignItems="center"
              justifyContent="center"
              top={0}
              left={0}
            >
              <Icon icon="lock" color="black" size={36} color="grayDark" />
              <Text
                textAlign="center"
                marginTop={3}
                color="grayDark"
                fontWeight={3}
              >
                Checked out by another user
              </Text>
            </Column>
          )}
        </Column>

        <Link to="/" style={{ alignSelf: 'start', marginTop: '24px' }}>
          <Button variant="white">Cancel</Button>
        </Link>
      </Column>
      <ConfirmPopup
        title="Delete"
        description="Are you sure you want to permanently delete this document?"
        show={deleting}
        onConfirm={submitDelete}
        onClose={() => setDeleting(false)}
      />
      <SuccessPopup show={!!success} />
      <ErrorPopup show={!!error} error={error} onClose={() => setError(null)} />
    </>
  );
};

const Review = () => {
  const [file, setFile] = useState();
  const [error, setError] = useState();
  const { filename } = useParams();
  const history = useHistory();

  const fetchFile = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/documents/${filename}`, {
        credentials: 'include',
      });
      const json = await response.json();
      setFile(json);
    } catch (e) {
      setError(e);
    }
  };

  const lockFile = async () => {
    if (file) {
      await fetch(`${config.apiUrl}/documents/${file.filename}`, {
        method: 'POST',
        body: JSON.stringify({
          available: false,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
    }
  };

  useEffect(() => {
    fetchFile();
  }, []);

  useEffect(() => {
    const lockFileInterval = setInterval(lockFile, KEEP_ACTIVE_INTERVAL);
    return () => clearInterval(lockFileInterval);
  }, [file]);

  if (error) {
    return (
      <Layout>
        <ErrorPopup show error={error} onClose={() => history.push('/')} />
      </Layout>
    );
  }

  if (file) {
    return (
      <Row alignSelf="stretch" flex={1} height="100%" overflow="hidden">
        <Column padding={3} flex={3}>
          <PDF
            url={file.url}
            onError={(error) => {
              console.log(error);
              setError(
                `The document you're attempting to view cannot be found in the queue. It's possible another user has processed the document.`
              );
            }}
          />
        </Column>

        <Column
          flex={1}
          padding={3}
          paddingRight={5}
          overflow="hidden"
          height="100%"
        >
          <FileForm file={file} />
        </Column>
      </Row>
    );
  }

  return (
    <Layout>
      <Spinner size={4} />
    </Layout>
  );
};

export default Review;
