import React, { forwardRef } from 'react';

import { Column } from './box';

const Card = forwardRef(({ children, ...props }, ref) => {
  return (
    <Column
      bg="grayLight"
      padding={6}
      borderRadius={2}
      width={12}
      boxShadow={2}
      overflow="auto"
      ref={ref}
      {...props}
    >
      {children}
    </Column>
  );
});

export default Card;
