import React from 'react';

import { Row } from './box';
import Text from './text';

const Info = ({ error, ...props }) => {
  if (!error) {
    return null;
  }

  return (
    <Row
      borderRadius={1}
      padding={3}
      border={0}
      borderColor="reds.3"
      bg="rgba(245,	86,	86, .2)"
      {...props}
    >
      <Text>{error}</Text>
    </Row>
  );
};

export default Info;
