import styled from 'styled-components';

import { Box } from './box';

const Input = styled(Box).attrs({ as: 'input' })`
  border: 2px solid ${(props) => props.theme.colors.black};
  outline: none;
  transition: ${(props) => props.theme.transitions[0]};
  width: 100%;
  padding: 6px;
  caret-color: ${(props) => props.theme.colors.black};
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadows[0]};
  transition: ${(props) => props.theme.transitions[0]};
  border-radius: 2px;

  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
  }

  font-size: 16px;

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
  }
`;

export default Input;
