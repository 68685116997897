export const decodeJWT = (token) => {
  const [header, payload] = token.split('.');
  const str = atob(payload);
  return JSON.parse(str);
};

export const getUserName = ({ email, firstName, lastName }) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return firstName || email || '';
};
