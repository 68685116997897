import React, { useState, useEffect, useContext } from "react";
import fetch from "unfetch";
import { Route, Redirect } from "react-router-dom";
import queryString from "query-string";

import { store } from "../store";
import config from "../config";
import { decodeJWT } from "../utils";

import Layout from "./layout";
import Spinner from "./spinner";

const Authenticate = ({ code }) => {
  const context = useContext(store);
  const [loading, setLoading] = useState(true);

  const authenticateSSO = async () => {
    const response = await fetch(`${config.apiUrl}/auth/sso`, {
      method: "POST",
      body: JSON.stringify({
        code,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    try {
      const json = await response.json();
      if (json && json.user) {
        context.setUser(json.user);
      }
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    authenticateSSO();
  }, []);

  if (loading) {
    return (
      <Layout>
        <Spinner />
      </Layout>
    );
  }

  return <Redirect to="/" />;
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(store);

  const render = ({ location }) => {
    const { code } = queryString.parse(location.search);

    if (code) {
      return <Authenticate code={code} />;
    }

    if (user) {
      return <Component {...rest} />;
    }

    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  };

  return <Route render={render} {...rest} />;
};

export default PrivateRoute;
