import React from 'react';
import styled from 'styled-components';

import { Row } from './box';
import Text from './text';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckIcon = styled.svg`
  fill: none;
  stroke-width: 3px;
  stroke: ${(props) => props.theme.colors.white};
`;

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border: 2px solid
    ${(props) =>
      props.checked
        ? props.theme.colors.primary
        : props.disabled
        ? props.theme.colors.grayLight
        : props.theme.colors.black};
  background: ${(props) =>
    props.checked ? props.theme.colors.primary : props.theme.colors.white};
  border-radius: 2px;
  transition: ${(props) => props.theme.transitions[0]};
`;

const Container = styled(Row)`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  align-self: flex-start;
`;

const Checkbox = ({
  checked,
  label,
  onChange,
  marginY,
  marginTop,
  disabled,
  ...props
}) => {
  return (
    <Container
      disabled={disabled}
      onClick={() => !disabled && onChange(!checked)}
      marginY={marginY}
      marginTop={marginTop}
    >
      <HiddenCheckbox id={label} disabled={disabled} {...props} readOnly />
      <StyledCheckbox checked={checked} disabled={disabled}>
        <CheckIcon viewBox="0 0 24 24" width={16} height={16}>
          <polyline points="20 6 9 17 4 12" />
        </CheckIcon>
      </StyledCheckbox>
      {label && <Text paddingLeft={3}>{label}</Text>}
    </Container>
  );
};

export default Checkbox;
