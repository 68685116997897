import styled from 'styled-components';

import { Box } from './box';

const Label = styled(Box).attrs({ as: 'label' })`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.grayDarkest};
`;

export default Label;
