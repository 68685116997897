import styled from 'styled-components';

import { Box } from './box';

const Text = styled(Box).attrs({ as: 'span' })`
  word-wrap: normal;
`;

Text.defaultProps = {
  color: 'black'
};

export default Text;
