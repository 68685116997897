import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    font-family: ${(props) => props.theme.fonts.default};
    font-size: 16px;
    font-weight: 400;
    background-color: ${(props) => props.theme.colors.background};
    height: 100%;
  }

  html, body {
    text-rendering: optimizelegibility;
    shape-rendering: geometricprecision;
    -webkit-font-smoothing: antialiased;
  }

  body {
    height: 100%;
    display: flex;
    margin: 0;
    overflow: hidden;
  }

  #root {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  a {
    text-decoration: none;
    color: initial;
  }
`;

export default GlobalStyle;
