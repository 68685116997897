import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { StoreProvider } from './store';

import GlobalStyle from './components/global-style';
import Nav from './components/nav';
import PrivateRoute from './components/private-route';
import Login from './screens/login';
import Documents from './screens/documents';
import Review from './screens/review';
import Metrics from './screens/metrics';

function App() {
  return (
    <StoreProvider>
      <Router>
        <Nav />
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute path="/review/:filename" component={Review} />
          <PrivateRoute path="/metrics" component={Metrics} />
          <PrivateRoute path="/" exact component={Documents} />
          <Redirect to="/" />
        </Switch>
      </Router>
      <GlobalStyle />
    </StoreProvider>
  );
}

export default App;
