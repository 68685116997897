import { useContext } from 'react';
import useSWR from 'swr';
import fetch from 'unfetch';
import { useHistory } from 'react-router-dom';

import { store } from '../store';
import config from '../config';

const url = (route) => `${config.apiUrl}${route}`;

const handleResponse = async (response) => {
  let json, text;

  try {
    json = await response.json();
  } catch {}

  if (!json) {
    try {
      text = await response.text();
    } catch {}
  }

  return json || text;
};

const useRequest = (route, options) => {
  const context = useContext(store);
  const history = useHistory();
  const endpoint = url(route);
  let { data, error } = useSWR(
    endpoint,
    async () => {
      const response = await fetch(endpoint, {
        credentials: 'include',
      });
      const data = await handleResponse(response);
      return data;
    },
    options
  );

  if (data && data.statusCode === 401) {
    context.setUser(null);
    history.go('/login');
  }

  if (data && data.error) {
    error =
      data.error ||
      'An unknown server error has occured. Please try again later.';
  }

  return {
    data,
    error,
  };
};

export default useRequest;
