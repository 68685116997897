const breakpoints = ['0px', '600px', '1000px'];

const space = [
  0,
  4,
  8,
  12,
  16,
  24,
  32,
  48,
  64,
  96,
  128,
  256,
  384,
  448,
  512,
  768,
  1024,
];

const colors = {
  primary: '#137CBD',

  blueLight: '#8fbcbb',

  blue: '#88c0d0',

  blueDark: '#81a1c1',

  blueDarker: '#5e81ac',

  red: '#bf616a',

  green: '#a3be8c',

  yellow: '#ebcb8b',

  purple: '#b48ead',

  orange: '#d08770',

  black: '#2e3440',

  grayDarkest: '#3b4252',

  grayDarker: '#434c5e',

  grayDark: '#4c566a',

  grayLight: '#d8dee9',

  grayLighter: '#e5e9f0',

  grayLightest: '#eceff4',

  white: '#fff',

  background: '#fff',

  transparent: 'transparent',
};

export default {
  colors,

  space,

  sizes: space,

  fonts: {
    default: `Roboto,sans-serif`,
    code: 'source-code-pro,Menlo,Monaco,Consolas,monospace',
  },

  fontSizes: [12, 14, 16, 18, 24, 36, 48, 64, 72],

  fontWeights: [300, 400, 500, 700],

  radii: [0, 2, 4, 8, 12, 16, 999, '50%'],

  breakpoints,

  shadows: [
    '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  ],

  borders: [`1px solid ${colors.primary}`, `4px solid ${colors.primary}`],

  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints[0]})`,
    medium: `@media screen and (min-width: ${breakpoints[1]})`,
    large: `@media screen and (min-width: ${breakpoints[2]})`,
  },

  transitions: ['all 100ms ease'],
};
