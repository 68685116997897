import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

import useRequest from '../hooks/useRequest';
import { Row, Column } from '../components/box';
import Layout from '../components/layout';
import Text from '../components/text';
import Spinner from '../components/spinner';
import Icon from '../components/icon';
import { ErrorPopup } from '../components/popup';

const Item = styled(Row).attrs({ as: Link })`
  text-decoration: none;
  width: 100%;
  border: 2px solid ${(props) => props.theme.colors.grayLightest};
  cursor: pointer;
  &:hover,
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.blueDark};
  }
`;
Item.defaultProps = {
  borderRadius: 1,
  bg: 'grayLightest',
  padding: 2,
  width: '100%',
};

const Documents = () => {
  const { data: files, error } = useRequest('/documents', {
    refreshInterval: 3000,
  });
  const history = useHistory();

  if (error) {
    return (
      <Layout>
        <ErrorPopup show error={error} onClose={() => history.go('/')} />
      </Layout>
    );
  }

  if (files) {
    if (files.length) {
      return (
        <Layout>
          <Column overflow="auto" width="60%" paddingRight={4} height="580px">
            {files.map((file, i) => (
              <Item
                to={`/review/${file.filename}`}
                marginTop={i === 0 ? 0 : 3}
                key={file.filename}
              >
                <Row
                  flex={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text>{file.filename}</Text>
                  <Row alignItems="center">
                    {!file.available && (
                      <>
                        <Text
                          marginRight={2}
                          fontSize={0}
                          fontWeight={3}
                          color="grayLight"
                        >
                          CHECKED OUT
                        </Text>
                        <Icon icon="lock" color="black" size={18} />
                      </>
                    )}
                  </Row>
                </Row>
              </Item>
            ))}
          </Column>
        </Layout>
      );
    }
    return (
      <Layout>
        <Text fontSize={4} fontWeight={3}>
          No documents found
        </Text>
      </Layout>
    );
  }

  return (
    <Layout>
      <Spinner size={4} />
    </Layout>
  );
};

export default Documents;
