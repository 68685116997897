import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

import { Grid, Column } from '../components/box';
import Label from '../components/label';
import Layout from '../components/layout';
import Spinner from '../components/spinner';
import { ErrorPopup } from '../components/popup';
import theme from '../theme';

import useRequest from '../hooks/useRequest';

const lastWeek = [];
for (var i = 7; i > 0; i--) {
  lastWeek.push(moment().subtract(i, 'days'));
}

const Metrics = ({}) => {
  const { data: metrics, error } = useRequest('/metrics', {
    refreshInterval: 3000,
  });
  const history = useHistory();

  if (error) {
    return (
      <Layout>
        <ErrorPopup show error={error} onClose={() => history.go('/')} />
      </Layout>
    );
  }

  return (
    <Grid gridTemplateColumns="200px 1fr">
      <Sidebar>
        <Group>
          <Label>Select Report</Label>
          <div>Exception Types</div>
        </Group>
        <Group>
          <Label>Date Range</Label>
          <div>Last Week</div>
        </Group>
        <Group>
          <Label>Start Date</Label>
          <div>{moment().subtract(7, 'days').format('MM/DD/YY')}</div>
        </Group>
        <Group>
          <Label>End Date</Label>
          <div>{moment().format('MM/DD/YY')}</div>
        </Group>
      </Sidebar>
      <Column alignItems="center" justifyContent="center">
        <Graph metrics={metrics} range={lastWeek} />
      </Column>
    </Grid>
  );
};

const Graph = ({ metrics, range }) => {
  if (!metrics) {
    return <Spinner size={5} bg={theme.colors.blue} />;
  }

  const data = [];

  range.forEach((date) => {
    const metricsForDate = metrics.filter((metric) => {
      return date.isSame(metric.timestamp, 'day');
    });

    data.push({
      name: date.format('MM/DD/YY'),
      payoffAddress: metricsForDate.reduce(
        (count, metric) =>
          metric.isPayoff && metric.hasAddress ? ++count : count,
        0
      ),
      payoffMissed: metricsForDate.reduce(
        (count, metric) =>
          metric.isPayoff && !metric.hasAddress ? ++count : count,
        0
      ),
      notPayoff: metricsForDate.reduce(
        (count, metric) => (!metric.isPayoff ? ++count : count),
        0
      ),
    });
  });

  return (
    <BarChart
      width={1000}
      height={400}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis
        label={{
          value: 'Number of Documents',
          angle: -90,
          position: 'insideLeft',
        }}
      />
      <Tooltip />
      <Legend />
      <Bar dataKey="payoffAddress" stackId="a" fill={theme.colors.purple} />
      <Bar dataKey="payoffMissed" stackId="a" fill={theme.colors.blue} />
      <Bar dataKey="notPayoff" stackId="a" fill={theme.colors.orange} />
    </BarChart>
  );
};

const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.grayLightest};
`;

const Group = styled.div`
  margin-bottom: 16px;
`;

export default Metrics;
