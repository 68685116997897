import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useHistory } from 'react-router-dom';

import config from '../config';
import { getUserName } from '../utils';
import { store } from '../store';
import { Row, Column } from './box';
import Image from './image';
import Text from './text';
import Button from './button';
import Icon from './icon';
import logo from '../assets/logo.png';

const StyledNav = styled(Row).attrs({ as: 'nav' })``;

const Menu = ({ close }) => {
  const context = useContext(store);
  const history = useHistory();
  const ref = useRef();

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      close();
    }
  };

  const logout = async () => {
    await fetch(`${config.apiUrl}/auth/logout`, {
      method: 'POST',
      credentials: 'include',
    });
    close();
    context.setUser(null);
    history.push('/login');
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <Column
      ref={ref}
      position="absolute"
      top="50px"
      right={4}
      bg="grayLight"
      borderRadius={1}
      boxShadow={1}
    >
      <Button variant="minimal" onClick={logout}>
        <Row padding={4} paddingY={3}>
          <span>Sign out</span>
          <Icon icon="log-out" size={12} color="grayDarkest" marginLeft={2} />
        </Row>
      </Button>
    </Column>
  );
};

const Nav = () => {
  const location = useLocation();
  const context = useContext(store);
  const [menuVisibility, setMenuVisibility] = useState();

  if (!context.user) {
    return null;
  }

  const title = getTitle(location);

  return (
    <StyledNav
      alignItems="center"
      paddingY={2}
      paddingX={4}
      bg="grayLightest"
      position="relative"
    >
      <Row flex={1}>
        <Link to="/">
          <Image width="150px" src={logo} alt="Logo" />
        </Link>
      </Row>
      <Row justifyContent="center" flex={1}>
        <Text fontWeight={3}>{title}</Text>
      </Row>
      <Row flex={1} justifyContent="flex-end">
        <Row alignItems="center">
          <Text color="grayDark">{getUserName(context.user)}</Text>
          <Icon
            style={{ cursor: 'pointer' }}
            icon="user"
            size={24}
            color="primary"
            marginLeft={2}
            onClick={() => setMenuVisibility(!menuVisibility)}
          />
        </Row>
      </Row>
      {menuVisibility && <Menu close={() => setMenuVisibility(false)} />}
    </StyledNav>
  );
};

const getTitle = (location) => {
  if (location.pathname.includes('/review/')) {
    return location.pathname.substr(
      location.pathname.lastIndexOf('/') + 1,
      location.pathname.length
    );
  }
  if (location.pathname.includes('/metrics')) {
    return 'Metrics';
  }
  return 'Documents';
};

export default Nav;
