import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Box } from './box';

const bounce = keyframes`
0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
`;

const BounceOne = styled(Box)`
  border-radius: 100%;
  display: inline-block;
  animation: ${bounce} 1.4s infinite ease-in-out both;
  animation-delay: -0.32s;
`;

const BounceTwo = styled(Box)`
  border-radius: 100%;
  display: inline-block;
  animation: ${bounce} 1.4s infinite ease-in-out both;
  animation-delay: -0.16s;
`;

const BounceThree = styled(Box)`
  border-radius: 100%;
  display: inline-block;
  animation: ${bounce} 1.4s infinite ease-in-out both;
`;

const Spinner = ({ size, bg = '#fff' }) => {
  return (
    <Box>
      <BounceOne width={size} height={size} bg={bg} />
      <BounceTwo width={size} height={size} bg={bg} />
      <BounceThree width={size} height={size} bg={bg} />
    </Box>
  );
};

export default Spinner;
