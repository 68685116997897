import styled from 'styled-components';

import { Column } from './box';

const Layout = styled(Column)`
  overflow: hidden;
  height: 100%;
`;

Layout.defaultProps = {
  alignItems: 'center',
  flex: 1,
  paddingTop: 8,
  paddingLeft: 8,
  paddingRight: 8,
  paddingBottom: 6,
};

export default Layout;
