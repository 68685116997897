import React from 'react';
import styled, { css } from 'styled-components';
import { variant } from 'styled-system';

import { Box } from './box';
import Spinner from './spinner';

const variants = {
  variants: {
    secondary: {
      bg: 'blueDarker',
    },
    gray: {
      bg: 'grayDarkest',
      color: 'white',
    },
    white: {
      bg: 'white',
      border: '2px solid',
      borderColor: 'grayDark',
      padding: '8px',
      boxShadow: 'none',
      color: 'grayDarkest',
    },
    minimal: {
      bg: 'transparent',
      color: 'grayDarkest',
      boxShadow: 'none',
      padding: 0,
    },
  },
};

const StyledButton = styled(Box).attrs({ as: 'button' })`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: none;
  outline: none;
  font-family: inherit;
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px;
  padding: 12px;
  font-weight: 700;
  text-transform: uppercase;
  text-renderering: geometricPercision;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  transition: ${(props) => props.theme.transitions[0]};
  box-shadow: ${(props) => props.theme.shadows[0]};

  &:disabled {
    cursor: ${(props) => (props.loading ? 'pointer' : 'not-allowed')};
  }

  ${(props) =>
    props.disabled && !props.loading
      ? css`
          background: ${(props) => props.theme.colors.grayLight};
          color: ${(props) => props.theme.colors.white};
        `
      : ''}

  &:focus {
    outline: none;
  }

  ${variant(variants)}
`;

const Button = ({ loading, disabled, children, ...props }) => {
  disabled = loading || disabled;
  return (
    <StyledButton loading={loading} disabled={disabled} {...props}>
      {loading ? <Spinner size={2} /> : children}
    </StyledButton>
  );
};

export default Button;
