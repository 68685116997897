import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Row, Column } from './box';
import Card from './card';
import Button from './button';
import Text from './text';
import Paragraph from './paragraph';
import Icon from './icon';

const Overlay = styled(Column)`
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Popup = ({ children, onClose }) => {
  const ref = useRef();

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <Overlay>
      <Card bg="white" ref={ref}>
        {children}
      </Card>
    </Overlay>
  );
};

export const SuccessPopup = ({ show }) => {
  if (!show) {
    return null;
  }

  return (
    <Popup>
      <Column alignItems="center" overflow="hidden">
        <Icon icon="endorsed" size={48} color="green" marginBottom={4} />

        <Text fontSize={4} fontWeight={3} color="green">
          Success
        </Text>
      </Column>
    </Popup>
  );
};

export const ErrorPopup = ({ show, error, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <Popup onClose={onClose}>
      <Column alignItems="center" overflow="hidden">
        <Icon icon="error" size={48} color="red" marginBottom={4} />

        <Text fontSize={4} fontWeight={3} color="red" marginBottom={5}>
          Error
        </Text>

        <Paragraph marginBottom={7} textAlign="center">
          {error}
        </Paragraph>

        <Button variant="gray" onClick={onClose}>
          Close
        </Button>
      </Column>
    </Popup>
  );
};

export const ConfirmPopup = ({
  show,
  title,
  description,
  onClose,
  onConfirm,
}) => {
  if (!show) {
    return null;
  }

  return (
    <Popup onClose={onClose}>
      <Column alignItems="center" overflow="hidden">
        <Text fontSize={4} fontWeight={3} textAlign="center" marginBottom={4}>
          {title}
        </Text>

        <Paragraph marginBottom={7} textAlign="center">
          {description}
        </Paragraph>

        <Row alignSelf="stretch">
          <Button
            variant="primary"
            onClick={onConfirm}
            marginRight={4}
            flex={2}
          >
            Yes
          </Button>
          <Button variant="white" onClick={onClose} flex={1}>
            Cancel
          </Button>
        </Row>
      </Column>
    </Popup>
  );
};

export default Popup;
