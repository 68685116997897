import React, { useState, useContext, useEffect } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import logo from '../assets/logo.png';

import { store } from '../store';
import config from '../config';

import Layout from '../components/layout';
import Heading from '../components/heading';
import Image from '../components/image';
import Card from '../components/card';
import Info from '../components/info';
import Form from '../components/form';
import Button from '../components/button';
import Input from '../components/input';
import Label from '../components/label';
import { Row } from '../components/box';
import Spinner from '../components/spinner';

const Link = styled.a`
  display: flex;
  flex-direction: column;
`;

const Login = () => {
  const context = useContext(store);
  const location = useLocation();
  const history = useHistory();
  const [validating, setValidating] = useState(true);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if (!context.user) {
      validateSavedUser();
    }
  }, [context]);

  if (context.user) {
    return <Redirect to="/" />;
  }

  const handleSuccess = (user) => {
    context.setUser(user);
    const { from } = location.state || { from: { pathname: '/' } };
    history.replace(from);
  };

  const validateSavedUser = async () => {
    const response = await fetch(`${config.apiUrl}/auth/validate`, {
      method: 'POST',
      credentials: 'include',
    });

    try {
      const json = await response.json();
      if (json && json.user) {
        setValidating(false);
        handleSuccess(json.user);
      }
    } catch (e) {
      console.error(e);
    }

    setValidating(false);
  };

  const submit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const data = new FormData(event.target);

    const response = await fetch(`${config.apiUrl}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({
        email: data.get('email'),
        password: data.get('password'),
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    let user;

    try {
      const json = await response.json();
      if (json && json.user) {
        user = json.user;
      }
    } catch (e) {
      console.error(e);
    }

    if (user) {
      handleSuccess(user);
    } else {
      setLoading(false);
      setError('Incorrect username or password');
    }
  };

  if (validating) {
    return (
      <Layout>
        <Row
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <Spinner size={5} />
        </Row>
      </Layout>
    );
  }

  return (
    <Layout>
      <Image width="300px" src={logo} alt="Logo" />
      <Card marginTop={8}>
        <Heading variant="tertiary" marginBottom={6}>
          Sign in
        </Heading>
        <Info error={error} marginBottom={5} />
        <Form onSubmit={submit}>
          <Label htmlFor="email">Username</Label>
          <Input required marginBottom={5} name="email" id="email" />
          <Label htmlFor="password">Password</Label>
          <Input required type="password" name="password" id="password" />
          <Button type="submit" marginTop={5} loading={loading}>
            Sign in
          </Button>
        </Form>
      </Card>
    </Layout>
  );
};

export default Login;
